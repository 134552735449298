<template>
  <div class="out_login" @click="outLogin">退出登录</div>
</template>
<script>
export default {
  name: "outlogin",
  methods: {
    outLogin() {
      if (this.$route.path == "/chatRoomList") {
        this.$store.dispatch("setItem", {
          executorInfo: {},
        });
        this.$router.push({
          path: "/executorLogin",
        });
      } else {
        this.$store.dispatch("setItem", {
          userId: 0,
          userInfo: {},
        });
        this.$router.push({
          path: "/login",
        });
      }
    },
  },
};
</script>
<style>
.out_login {
  height: 3rem;
  padding: 0.5rem 0;
  text-decoration: underline;
  color: #a5a5a8;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e8ebf3;
}
</style>