<template>
  <div class="logo_content">
    <div class="logo_content_item">
      <span>主办方:</span>
      <img
        class="logo"
        :src="require('@/assets/images/home/A_logo.png')"
        alt=""
      />
    </div>
    <div class="logo_content_item">
      <span> 支持单位:</span>
      <img
        class="logo"
        :src="require('@/assets/images/home/B_logo.png')"
        alt=""
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "topbanner",
  components: {},
  data: () => {
    return {
      config: {},
    };
  },
  created() {},

  methods: {},
};
</script>
<style>
.logo_content {
  margin: 0 0.5rem;
  display: flex;
  height: 2rem;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
}
.logo_content_item {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.logo_content .logo {
  height: 1.8rem;
  object-fit: contain;
  margin-left: 0.3rem;
}
</style>
