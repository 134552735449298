<template>
  <div class="topBanner">
    <div class="topBanner_text">
      <span>活动流程：第一期火热进行中</span>
      <CustomButton
        size="small"
        @click="goToCustomer"
        type="success"
        class="topBanner_btn"
        >联系客服</CustomButton
      >
      <img
        :src="require('@/assets/images/home/logo.svg')"
        alt=""
        v-if="false"
      />
    </div>
    <div v-if="$tools.isLogin()">
      <div class="topBanner_info_block">
        <div class="topBanner_adminInfo">
          <div class="topBanner_adminInfo_item topBanner_adminInfo_name">
            <div><span class="colorRed">姓名:</span> {{ userInfo.name }}</div>
            <div>
              <span class="colorRed">医院:</span> {{ userInfo.hospital }}
            </div>
          </div>
          <div class="topBanner_adminInfo_item">
            <!-- <div>剩余次数: {{userInfo.join_count}}/{{ userInfo.meet_count }}</div> -->
            <div>
              <CustomButton class="meet_btn" size="small" @click="getDoctorInfo"
                >完善个人信息</CustomButton
              >
            </div>
            <div
              class="topBanner_info_status"
              v-if="
                ([0, 2].includes(userInfo.work_card_ocr_status) &&
                  userInfo.doctor_work_id_card) ||
                ([0, 2].includes(userInfo.work_card_ocr_status) &&
                  userInfo.professional_panoramic &&
                  userInfo.professional_person)
              "
            >
              {{ material[userInfo.work_card_ocr_status] }}
            </div>
            <div
              class="topBanner_info_status"
              v-if="
                [0, 2].includes(userInfo.id_card_ocr_status) &&
                userInfo.id_card_front &&
                userInfo.id_card_reverse &&
                userInfo.id_card_number &&
                userInfo.bank_name_point &&
                userInfo.bank_number &&
                userInfo.bank_province &&
                userInfo.bank_city &&
                userInfo.bank_name
              "
            >
              {{ idCard[userInfo.id_card_ocr_status] }}
            </div>
          </div>
        </div>
      </div>

      <div class="topBanner_block">
        <div
          class="topBanner_item"
          v-for="(item, index) in roles.slice(0, 3)"
          :key="index"
          :class="{
            bg_green: !item.code,
            topBanner_item_mymeet: !item.code,
          }"
        >
          <div
            class="topBanner_item_key"
            :class="{ topBanner_item_is_list: !item.code }"
          >
            <img
              :src="require(`@/assets/images/home/user-${img}.png`)"
              class="role_img"
              v-for="img in item.icon"
              :key="img"
            />
            <span v-if="item.code"
              >{{ item.label }}
              <span style="font-size: 0.6rem"
                >({{ item.meetJoinCount }}/{{ item.meetAllCount }})</span
              >
            </span>
            <div class="invite" v-if="userInfo.invite_count && !item.code">
              {{ userInfo.invite_count }}
            </div>
          </div>
          <div
            class="topBanner_item_value"
            v-if="item.code == $config.types.roles.jz"
          >
            <div class="myMeetList_null_div"></div>
            <div class="myMeetList_text">
              <CustomButton
                class="meet_btn"
                :class="
                  item.meetJoinCount >= item.meetAllCount
                    ? 'topBanner_disabled_btn'
                    : ''
                "
                size="small"
                @click="goTomeet(item)"
                >我要发贴</CustomButton
              >
            </div>
          </div>

          <div
            class="topBanner_item_value colorRed"
            v-else-if="
              [$config.types.roles.tl, $config.types.roles.zx].includes(
                item.code
              )
            "
          >
            <div class="topBanner_null_div"></div>
            <div class="topBanner_text_tips">共{{ item.count }}场可选</div>
          </div>

          <div
            v-else
            class="topBanner_item_value colorRed"
            style="font-weight: bold"
            @click="goToMeetList"
          >
            {{ item.label }}（<span style="color: #000">{{ item.count }}</span
            >）<span class="invite" v-if="userInfo.invite_count"
              >您有{{ userInfo.invite_count }}个邀请待处理</span
            >
          </div>
        </div>
      </div>
      <div
        class="topBanner_block"
        v-if="roles.length > 3"
        @click="goToMeetList()"
      >
        <div class="topBanner_item bg_green topBanner_item_mymeet">
          <div class="topBanner_item_key topBanner_item_is_list">
            <img
              :src="require(`@/assets/images/home/user-${img}.png`)"
              class="role_img"
              v-for="img in roles[3].icon"
              :key="img"
            />
            <span v-if="roles[3].code">{{ roles[3].label }}</span>
            <div class="invite" v-if="userInfo.invite_count">
              {{ userInfo.invite_count }}
            </div>
          </div>

          <div class="topBanner_item_value colorRed" style="font-weight: bold">
            {{ roles[3].label }}（<span style="color: #000">{{
              roles[3].count
            }}</span
            >）

            <span class="invite" v-if="userInfo.invite_count"
              >您有{{ userInfo.invite_count }}个邀请待处理</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="topBanner_info_block topBanner_nologin" v-else>
      <div class="topBanner_user">
        <span class="topBanner_user_field">用户名：</span>
        <span class="topBanner_user_name">游客</span>
      </div>
      <CustomButton size="small" @click="goToLogin">登录</CustomButton>
    </div>
    <div class="topBanner_desc">
      *本期活动中每人最多参与3次，上方数字为已加入会议/权益次数总数
    </div>
  </div>
</template>
<script>
import CustomButton from "@/components/unit/CustomButton";
import config from "@/common/config";
import PopopInstance from "@/components/unit/PopupInstance";
export default {
  name: "topbanner",
  components: {
    CustomButton,
  },
  data: () => {
    return {
      customerInfo: {},
      config: {
        welcome: {
          title: "客服联系方式",
          align: "center",
          html: "",
          btngroup: {
            sure: true,
          },
        },
        roles: [
          {
            code: config.types.roles.jz,
            label: "会议讲者",
            icon: [2],
            count: 1,
            title: "speaker",
          },
          {
            code: config.types.roles.tl,
            label: "讨论嘉宾",
            icon: [3],
            count: 1,
            title: "guest",
          },
          {
            code: config.types.roles.zx,
            label: "会议主席",
            icon: [1],
            count: 0,
            title: "cohosts",
          },
          {
            code: "",
            label: "我的会议",
            icon: [1, 2, 3, 4],
            count: 1,
          },
        ],
      },
      material: ["职称资料审核中", "", "职称资料审核失败"],
      idCard: ["身份证审核中", "", "身份证审核失败"],
      materialBG: { 10: "职称等级审核中", 30: "职称等级审核失败" },
      roles: [],
      userInfo: {},
    };
  },
  created() {
    // console.log(this.$store.state.common);
    if (this.$tools.isLogin()) {
      this.loadData();
      this.getCustomerInfo();
    }
  },

  methods: {
    loadData() {
      this.$axios
        .get(this.$urls.user.info)
        .then((res) => {
          this.$store.dispatch("setItem", { userInfo: res.data });
          this.userInfo = res.data;
          this.initRoles();
        })
        .catch(() => {});
    },
    getCustomerInfo() {
      let url = this.$tools.getURL(this.$urls.live.customerInfo);
      this.$axios.get(url).then((res) => {
        res.data;
        this.customerInfo = res.data;
      });
    },
    initRoles() {
      let roles = [];
      let rolesCode = this.userInfo.roles.map((role) => role.role_code);
      this.config.roles.forEach((role) => {
        if (!role.code) {
          roles.push({
            ...role,
            count: `${this.userInfo.join_count}/${this.userInfo.meet_count}`,
          });
        } else {
          if (rolesCode.includes(role.code)) {
            let moreObj = {};
            if (role.code == this.$config.types.roles.tl) {
              moreObj.count = this.userInfo.wait_post_count;
            }
            if (role.code == this.$config.types.roles.zx) {
              moreObj.count = this.userInfo.wait_cohosts_count || 0;
            }
            role.meetJoinCount = this.userInfo[`join_${role.title}_count`];
            role.meetAllCount = this.userInfo[`${role.title}_count`];
            roles.push({
              ...role,
              ...moreObj,
            });
          }
        }
      });
      this.roles = roles;
    },
    getDoctorInfo() {
      this.$router.push({
        path: "/doctorInfo",
        query: {
          from: this.$route.path,
          // type: 1,
        },
      });
    },

    goTomeet(item) {
      if (item.meetJoinCount >= item.meetAllCount)
        return this.$tips.error({ text: "您的发贴次数已达上限" });
      if (!this.userInfo.first_complete)
        return this.$tips.error({ text: "完善个人信息后，方可发帖" });
      if (this.userInfo.work_card_ocr_status != 1)
        return this.$tips.error({
          text: "职称资料审核通过后，方可发帖",
        });
      this.$router.push({
        path: "/applyMeet",
        query: {
          // ...query,
        },
      });
    },
    goToMeetList() {
      this.$router.push({
        path: "/myMeetList",
        query: {
          // ...query,
        },
      });
    },
    goToLogin() {
      this.$router.push({
        path: "/login",
        query: {
          // ...query,
        },
      });
    },
    goToCustomer() {
      if (this.$tools.isLogin()) {
        this.config.welcome.html = `
                  <div class="wx_qrcode_block"><img  class="wx_qrcode_img" src="${this.customerInfo.link}" alt="" />
      <div >\n请扫描上方二维码添加客服</div><div>若您有紧急事项需要联系，请拨打客服电话：19113959856</div><div>客服在线时间为工作日9:30-18:30\n非工作日期间如有问题，请您留言，我们将在上线后第一时间与您联系，感谢您的理解与支持</div></div>`;
      } else {
        this.config.welcome.html = `<div class="wx_qrcode_block"><div>若您有紧急事项需要联系，请拨打客服电话：19113959856</div><div>客服在线时间为工作日9:30-18:30\n非工作日期间如有问题，请您留言，我们将在上线后第一时间与您联系，感谢您的理解与支持</div></div>`;
      }
      PopopInstance(this.config.welcome)
        .then(() => {
          this.$nextTick(() => {
            // this.meetingInfo.isCanPlay = true;
            // this.$refs.player.play();
          });
        })
        .catch(() => {
          console.log("关闭");
        });
    },
  },
};
</script>
<style>
.topBanner {
  padding: 0.5rem 0.75rem 0.55rem 0.75rem;
  background: linear-gradient(rgb(230, 70, 56) 39%, rgb(247, 222, 234) 179%);
}
.topBanner_text {
  color: #fff;
  margin-bottom: 0.5rem;
  /* opacity: 0.8; */
  font-size: 0.8rem;
  /* padding: 0.2rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.topBanner_text img {
  width: 3.5rem;
  object-fit: contain;
}
.topBanner_text .meet_btn {
  width: 4.5rem;
  padding: 0 !important;
  white-space: nowrap;
}
.topBanner_info_block {
  width: 100%;
  margin-bottom: 0.5rem !important;
  display: flex !important;
  padding: 0.5rem;
  background: #fff;
  border-radius: 0.125rem;
}
.topBanner_nologin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
}
.topBanner_user_field {
  color: #e64638;
  font-weight: 600;
}
.topBanner_adminInfo {
  width: 100%;
  border-radius: 0.125rem;
  /* padding: 0.5rem; */
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
}
.topBanner_adminInfo_name div {
  font-size: 0.9rem;
}
.topBanner_adminInfo_item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1rem;
}
.topBanner_adminInfo_item > div:first-child {
  margin-bottom: 0.1rem;
}
.topBanner_adminInfo_item:last-child {
  align-items: flex-end;
}

.topBanner_adminInfo_item .customButton_container {
  height: 1rem;
}
.topBanner_item_is_list {
  justify-content: center;
  position: relative;
}

.topBanner .meet_btn {
  height: 1.4rem !important;
  background: #e64638;
  color: #fff;
  font-size: 0.8rem !important;
  white-space: nowrap;
  margin: 0;
  padding: 0 0.5rem;
}

.topBanner_block {
  width: 100%;
  display: flex;
  min-height: 3.75rem;
  margin-bottom: 0.5rem !important;
  /* justify-content: space-between !important; */
}
.topBanner_item {
  font-size: 0.75rem;
  /* width: 7.8rem; */
  background: #fff;
  border-radius: 0.125rem !important;
  padding: 0.5rem 0.25rem !important;
  display: flex;
  flex-direction: column;
  margin-right: 2%;
  flex: 32% 0 0;
}
.topBanner_item_mymeet {
  flex: 1 0 0;
}
.topBanner_item:last-child {
  margin-right: 0;
}
.topBanner .bg_green {
  background: linear-gradient(180deg, #bedb41 -61%, #f3ffbc 100%) !important;
}
.topBanner_item_key {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  font-size: 0.8rem;
  height: auto;
}
.topBanner .role_img {
  width: 1.25rem;
  height: auto;
}
.topBanner_item_value {
  flex: 1 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.topBanner_text_tips {
  /* display: inline-block; */
  flex: 1 0 0;
}
.topBanner_null_div {
  flex: 1.25rem 0 0;
}
.topBanner_desc {
  font-size: 0.6rem;
  color: #fff;
  margin-top: 0.7rem;
}
.topBanner_info_status {
  color: red;
  line-height: 1rem;
}
.topBanner_disabled_btn {
  background: #ccc !important;
}
.topBanner_item_is_list .invite {
  position: absolute;
  right: -0.2rem;
  top: -0.5rem;
  font-size: 0.6rem;
  width: 1rem;
  height: 1rem;
  background: #e64638;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  line-height: 1rem;
}
.topBanner_item_value .invite {
  position: absolute;
  right: -0.2rem;
  bottom: -0.5rem;
  font-size: 0.6rem;
}
.topBanner .topBanner_btn {
  margin: 0 !important;
  height: 1.4rem !important;
}
.wx_qrcode_block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.wx_qrcode_block div {
  font-size: 0.8rem;
}
.wx_qrcode_img {
  width: 80%;
  object-fit: contain;
  height: auto;
}
</style>
